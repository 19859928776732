import React from "react"
import { Provider } from "react-redux"
import { hot } from 'react-hot-loader/root'


import store from "../stores/index"
import RootContainer from "../containers/root-container"

const App = () => (
  <Provider store={store}>
    <RootContainer />
  </Provider>
)

export default hot(App)