import React, { lazy } from 'react'
import {
  HOME_PATH,
  DRESSING_ROOM_PATH,
  SALES_PATH,
  CONTACT_PATH,
  PRIVACY_PATH,
  THANK_PATH,
  CAREER_PATH,
  PRODUCT_ARCHITECTURE,
  DOWNLOAD_PATH,
  SERVICE_PATH,
  ABOUT_PATH,
  SERVICE_AGREEMENT_PATH,
} from '../constants/route-paths'


const HomePage = lazy(
  () => import('../containers/home/home-page')
)

const DressingRoomPage = lazy(
  () => import('../containers/dressing-room/dressing-room-page')
)

const SalesPage = lazy(
  () => import('../containers/sales/sales-page')
)

const PrivacyPage = lazy(
  () => import('../containers/privacy/privacy-page')
)

const ContactPage = lazy(
  () => import('../containers/contact/contact-page')
)

const ThankPage = lazy(
  () => import('../containers/others/thank-page')
)

const CareerPage = lazy(
  () => import('../containers/others/career-page')
)

const ProductArchitecturePage = lazy(
  () => import('../containers/products/product-architecture-page')
)

const DownloadPage = lazy(
  () => import('../containers/others/download-page')
)

const RetailServicePage = lazy(
  () => import('../containers/services/retail-service-page')
)

const AboutPage = lazy(
  () => import('../containers/others/about-page')
)

const ServiceAgreementPage = lazy(
  () => import('../containers/agreement/service-agreement-page')
)

const HOME_ROUTE = {
  name: "首页",
  path: HOME_PATH,
  exact: true,
  component: () => <HomePage />,
}

const DRESSING_ROOM_ROUTE = {
  name: "试衣间",
  path: DRESSING_ROOM_PATH,
  component: () => <DressingRoomPage />,
}

const SALES_ROUTE = {
  name: "微分销",
  path: SALES_PATH,
  component: () => <SalesPage />,
}

const CONTACT_ROUTE = {
  name: "联系我们",
  path: CONTACT_PATH,
  component: () => <ContactPage />,
}

const PRIVACY_ROUTE = {
  name: "隐私声明",
  path: PRIVACY_PATH,
  component: () => <PrivacyPage />,
}

const THANK_ROUTE = {
  name: "非常感谢",
  path: THANK_PATH,
  component: () => <ThankPage />,
}

const CAREER_ROUTE = {
  name: "加入我们",
  path: CAREER_PATH,
  component: () => <CareerPage />,
}

const PRODUCT_ARCHITECTURE_ROUTE = {
  name: "产品架构",
  path: PRODUCT_ARCHITECTURE,
  component: () => <ProductArchitecturePage />,
}

const DOWNLOAD_ROUTE = {
  name: "下载",
  path: DOWNLOAD_PATH,
  component: () => <DownloadPage />,
}

const RETAIL_SERVICE_ROUTE = {
  name: "服务",
  path: SERVICE_PATH,
  component: () => <RetailServicePage />,
}

const ABOUT_ROUTE = {
  name: "关于",
  path: ABOUT_PATH,
  component: () => <AboutPage />,
}

const SERVICE_AGREEMENT_ROUTE = {
  name: "服务协议",
  path: SERVICE_AGREEMENT_PATH,
  component: () => <ServiceAgreementPage />,
}

const routes = [
  CONTACT_ROUTE,
  DRESSING_ROOM_ROUTE,
  HOME_ROUTE,
  PRIVACY_ROUTE,
  SALES_ROUTE,
  THANK_ROUTE,
  CAREER_ROUTE,
  PRODUCT_ARCHITECTURE_ROUTE,
  DOWNLOAD_ROUTE,
  RETAIL_SERVICE_ROUTE,
  ABOUT_ROUTE,
  SERVICE_AGREEMENT_ROUTE,
]

export default routes