import 'react-hot-loader'
import React from 'react'
import { render } from "react-dom"

import App from '../apps/app'

render( 
  <App />
  ,
  document.getElementById("root")
)