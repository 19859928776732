import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom"

import routes from '../routes/root-route'
import AdmsContainer from './commons/adms-container'
import ScrollToTop from './commons/scroll-to-top'


class RootContainer extends React.Component {

  render() {

    return (
      <div>
        <AdmsContainer />
        <Router>
          <ScrollToTop />
          <Suspense fallback={<div style={{color:'SeaShell'}}>加载中</div>}>
            {
              routes.map((route, index) => (
                <Route
                  key={index.toString()}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))
            }
          </Suspense>
        </Router>
      </div>
    )
  }
}

export default RootContainer