import React from 'react'
import { connect } from "react-redux"
import { Message } from 'semantic-ui-react'

class AdmsContainer extends React.Component {

  render() {
    const { admsInfo } = this.props
    const { messages } = admsInfo
    return (
      <div style={{ position: 'fixed', left: '50%', top: 10, zIndex: 99999 }}>
        <div style={{ position: 'relative', left: '-50%' }}>
          {messages.map((message) =>
            (
              <div key={message.key}>
                <Message compact floating size='tiny' {...message.type}>
                  {message.content}
                </Message>
              </div>
            )
          )}
        </div>
      </div>
    )
  }

}

export default connect(
  (state) => ({
    admsInfo: state.admsInfo,
  }),
  {}
)(AdmsContainer)