import {
  all,
} from 'redux-saga/effects'

import admsWatcher from './adms-saga'

export default function* rootSaga() {
  yield all([
    admsWatcher(),
  ])
}