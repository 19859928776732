import {
  combineReducers,
} from 'redux'

import admsReducer from './adms-reducer'
import authReducer from './auth-reducer'

// Root Reducer
const rootReducer = combineReducers({
  admsInfo: admsReducer,
  authInfo: authReducer,
})

export default rootReducer