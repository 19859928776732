import {
  FETCH_SECURITY_USER_RESPONSE,
} from '../constants/action-types'

const initialState = {
  isLoading: false,
  isForbidden: false,
  isLoggedIn: false,
  isTriedFetchSecurityUser: false,
  securityUser: null,
}

const authReducer = (state = initialState, action) => {

  const {
    payload,
  } = action
  switch (action.type) {
    case FETCH_SECURITY_USER_RESPONSE: {
      return {
        ...state,
        isLoading: false,
        isTriedFetchSecurityUser: true,
        isForbidden: payload.status === 303,
        isLoggedIn: payload.status !== 300,
        securityUser: payload.body,
      }
    }
    default: {
      return state
    }
  }
}

export default authReducer