// *system*
export const NETWORK_ERROR = "NETWORK_ERROR"

// *biz*
export const BIZ_FAIL = "BIZ_FAIL"
export const BIZ_INFO = "BIZ_INFO"
export const BIZ_SUCCESS = "BIZ_SUCCESS"
export const BIZ_WARN = "BIZ_WARN"

// *adms - auto dimission messages*
export const ADMS = "ADMS"

// auth
export const INIT_AUTH = "INIT_AUTH"
export const FETCH_SECURITY_USER = "FETCH_SECURITY_USER"
export const FETCH_SECURITY_USER_RESPONSE = "FETCH_SECURITY_USER_RESPONSE"
