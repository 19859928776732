
export const ABOUT_PATH = "/about"
export const HOME_PATH = "/"
export const DRESSING_ROOM_PATH = "/dressing-room"
export const SALES_PATH = "/sales"
export const CONTACT_PATH = "/contact"
export const PRIVACY_PATH = "/privacy"
export const THANK_PATH = "/thank"
export const CAREER_PATH = "/career"
export const PRODUCT_ARCHITECTURE = "/product-architecture"
export const DOWNLOAD_PATH = "/download"
export const SERVICE_PATH = "/service"
export const SERVICE_AGREEMENT_PATH = "/service-agreement"