import {
  takeEvery,
  put,
} from "redux-saga/effects"
import {
  NETWORK_ERROR,
  BIZ_FAIL,
  BIZ_INFO,
  BIZ_SUCCESS,
  BIZ_WARN,
  ADMS,
} from "../constants/action-types"

const delay = (ms) => new Promise(res => setTimeout(res, ms))

let counter = 0
const messages = []

const getMessageType = (bizType) => {
  switch (bizType) {
    case BIZ_FAIL:
      return {
        negative: true,
      }
    case BIZ_INFO:
      return {
        info: true,
      };
    case BIZ_SUCCESS:
      return {
        positive: true,
      }
    case BIZ_WARN:
      return {
        warning: true,
      }
    default:
      return {
        info: true,
      }
  }
}

export default function* admsWatcher() {
  yield takeEvery(NETWORK_ERROR, networkErrorWorker)
  yield takeEvery([BIZ_FAIL, BIZ_INFO, BIZ_SUCCESS, BIZ_WARN], bizMessageWorker)
}

function* bizMessageWorker(action) {
  counter += 1
  messages.push({
    type: getMessageType(action.type),
    content: action.payload,
    key: counter.toString(),
  })
  yield put({
    type: ADMS,
    payload: messages,
  })
  yield delay(3000)
  messages.shift()
  yield put({
    type: ADMS,
    payload: messages,
  })
}

function* networkErrorWorker() {
  counter += 1
  messages.push({
    type: {
      negative: true,
    },
    content: "网络错误",
    key: counter.toString(),
  })
  yield put({
    type: ADMS,
    payload: messages,
  })
  yield delay(3000)
  messages.shift()
  yield put({
    type: ADMS,
    payload: messages,
  })
}